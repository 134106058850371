<template>
  <div
    class="flex flex-col items-center justify-center h-screen text-center bg-white dark:bg-gray-900 dark:text-gray-300"
  >
    <NotoShoppingBags class="w-36 h-36" />
    <p class="mt-4 text-xl font-bold font-display md:text-4xl">
      {{ title }}
    </p>
    <p v-if="desc" class="mb-2 text-gray-500">{{ desc }}</p>

    <VyButton
      class="mt-2"
      size="xl"
      color="primary"
      shape="rounded"
      theme="solid"
      :label="actionLabel"
      :to="actionRoute"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    actionLabel: {
      type: String,
      default: null,
    },
    actionRoute: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    desc: {
      type: String,
      default: null,
    },
  },
};
</script>
